@charset "UTF-8";

/// Provides a quick method for targeting `border-style` on specific sides of a box. Use a `null` value to “skip” a side.
///
/// @param {Arglist} $vals
///   List of arguments
///
/// @example scss - Usage
///   .element {
///     @include border-style(dashed null solid);
///   }
///
/// @example css - CSS Output
///   .element {
///     border-bottom-style: solid;
///     border-top-style: dashed;
///   }
///
/// @require {mixin} directional-property
///
/// @output `border-style`

@mixin border-style($vals...) {
  @include directional-property(border, style, $vals...);
}
