/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andri Samsuri
==============================================================================================*/
@import "sass-lib";
@media all and (max-width: 1890px) {

	.wrapper {width: 1170px; position: relative;} 

	.btn {
		&.slide {
			height: 50px; line-height: 10px; display: inline-block;   
			@include boxSizing(border-box); 
		}
		&.yell {
			font-size: 12px; padding: 10px 20px; margin-top: 30px !important;
			a {font-size: 12px;} 
		}
		&.load-more {
			padding: 15px 35px; font-size: 12px; 
			&.mid {padding: 15px 35px; font-size: 12px; margin-top: 25px;}  
		}  
	}

	header {
		.bot {
			.left_header {
				left: 0; top: 0; 
				a { display: block;}  
				.logotop{ width: 200px; }  
			} 
			.right_header {
				nav {
					ul {
						li {
							
							a {font-size: 13px;}  
							ul {
								li {
									a {
										font-size: 12px; line-height: 17px; 
										padding: 15px 15px 15px 20px; 
									}
								}
							}
						}
					}
				}
			}
		}
		&.float {  
			.bot {
				.left_header {padding-top: 15px;}   
			}
		}
	} 
	.banner-slider {   
		.list-slider {
			.box-caption {
				left: 0;
				.tittle-capt { 
					height: auto; padding-left: 30px; margin-bottom: 20px;       
					i {font-size: 20px;}
					h1 {font-size: 40px; margin-top: 10px;}       
				}
			} 
		}
		.slick-dots {width: 100%; padding: 0 35px; bottom: 30px; @include boxSizing(border-box);}   
	}
	.home {   
		.tittle {
			h1 {padding:65px 0 55px 0; font-size: 32px;} 
			&.blue {font-weight: bold;} 
		}
		&.visimisi {  
			.tittle-sec {   
				margin: 100px auto 40px auto; height: auto; padding-bottom: 25px;        
				h2 {font-size: 35px;} 				
				p {}
			}
			.box-visimisi {
				padding-bottom: 50px; min-height: 477px;
				h3 {font-size: 30px; line-height: 35px;}
				p {font-size: 16px; line-height: 24px;}
			}     
		} 
		span.tgl.wht {font-size: 12px;}
		&.news-event { 
			padding-bottom: 70px;  
			.tittle-news {padding-top: 65px; margin-bottom: 30px;} 
			h1 {font-size: 30px;}   
			.box-list {
				.box {
					.sub-box { 
						height: 298px; position: relative; padding: 25px 20px 10px 20px;     
						a {
							display: block; position: relative; height: 100%;
							h4 {font-size: 22px; line-height: 26px; margin: 24px 0;}   
							.loc {
								padding-top: 20px; 
								width: 100%; left: 0; bottom: 0; @include boxSizing(border-box);
								h5 {font-size: 12px;} 
							}
						}
					}
					&.big {
						figure { 
							line-height: 0;
							.caption {left: 25px;}   
							i {
								&.round {left: 25px;}  
							}
							span.ico {right: 25px;} 
							.caption {
								h5 {
									font-size: 20px; line-height: 26px; padding-top: 0px;
								}
							}
						}  
					}
				}
			}
		}
		&.family {
			padding-bottom: 245px;
			.box-img  {
				.list-img {
					width: 33.333%;		 			
					.label { 
						left:20px; bottom: 20px;   
						h5 {font-size: 18px;}
					}						
				}       
			}
		}
		&.number {
			.box-top {
				// margin-top: -170px; 
				.box-info {
					margin-bottom: 80px;
					.list-info {
						width: 24.9%;
						.isi {
							h2 {font-size: 30px;}
							i.info {font-size: 14px;}
						}
					}   
				}
			}
			.box-bot {
				padding-bottom: 100px;  
				.tittle-info {
					padding-bottom: 35px;
					h1 {font-size: 30px;} 
				}
			}
		}
		
		&.virtual {
			.caption-virtual {
				h1 {font-size: 30px;}
				p {font-size: 16px;} 
				a {font-size: 12px;}
			}
		}

	}
	
	.map {
		.txt-map { 
			padding: 30px 40px; @include boxSizing(border-box);      
			h5 {font-size: 22px;}
			p {font-size: 14px; line-height: 20px;} 
		}
	}

	.box-circ {
		margin-bottom: 90px;
		.list-circ {
			.circle {
				width: 95px; height: 95px; position: relative;
				h6 {
					position: absolute; left: 50%; top: 50%; width: 100%;
					line-height: 18px; font-size: 11px; padding: 0 5px;
					@include transform(translate(-50%,-50%)); 
					@include boxSizing(border-box);
				}  
			}
		}
	}
	.slide-overview {
		margin-top: 55px; 
		.slider-text {
			height: 280px;  
			.text {
				h3 {font-size: 22px; line-height: 28px;}
				h6 {font-size: 14px;}
			}
			.slick-arrow {
				width: 50px; height: 40px; bottom: -20px;
				&.slick-prev {right: 50px;} 
			}
		}
	}
	.box-drop-dots-menu {    
		ul {
			li {
				a {font-size: 13px; padding: 10px 20px 10px 10px;}
				&.drop > {
					a {
						&:before {top:15px;}  
					} 
				}
			}
		}
	}
	.list-acc {       
		.grid-acc { 
			padding: 17px 20px; margin-bottom: 10px;  
			&:before {top:20px;}
			.tittle-acc {font-size: 20px;}  
		}
		&:last-child { 
			padding-bottom: 40px;      
		} 
		.content-acc {
			padding-top: 10px;     
			ul {
				padding-top: 0 !important;  
			}
		}   
	} 
	.content-std {       
		.inner-content {  
			&.filt {
				padding:60px 95px 30px 95px;  
			}
			padding-bottom: 80px;   
			ol {
				li {font-size: 16px; line-height: 22px;}   
			}
			ul {
				padding-top: 25px;
				li {font-size: 16px; line-height: 22px; margin-bottom: 10px;}
			}
			.infor-collage {  
				&.requir {padding: 35px;}   
			}
			&.filt { 
				.wrap-select { 
					span {top:15px;}
					select {height: 40px;}
				} 
			}
			.infor-collage {
				padding: 20px;
				a.click-here {font-size: 14px; padding-top: 5px;}
			}
			.contact-infor {
				.list-contact {
					.bot-con {
						h6 {font-size: 14px;}  
					}
				}
			}
		}

		h1 {font-size: 28px; padding-bottom: 25px; margin-bottom: 30px;}  
		h2 {font-size: 22px; line-height: 26px;} 
		h3 {
			font-size: 20px; line-height: 24px; 
			&.bl-f1 {font-size: 20px;} 
		}
		h5 {font-size: 16px;} 
		h6 {
			&.itl {font-size: 14px;}
		}
		p { 
			font-size: 16px; line-height: 28px;  
			&.gry {font-size: 16px; line-height: 24px;}   
		}

		.sub-content {
			.left-absolute {left: 0;}   
		}	
		
		 .wrap-box-admission {  
		 	.list-box {
		 		.box {
		 			padding: 25px;
					h4 {font-size: 20px;}
					h5 {font-size: 14px;} 
		 		} 		 		
		 		.grid-email {
		 			&:before {left:54px;}
		 			img {left: 12px;}
		 			span {
		 				font-size: 12px; left: 65px;   
		 			}
		 		}
		 	} 
		 }  
		 .wrap-box-testimoni {     
		 	.list-testimoni {
		 		.box-testi {
		 			.text {
		 				padding-bottom: 10px;
		 				&:before {width: 40px; height: 40px; background-size: 100%;} 
		 				h4 {font-size: 20px;}
		 				p {font-size: 14px; line-height: 20px; padding-top: 5px;}
		 			}
		 			.bot-testi {padding: 6px 30px;} 
		 			.bld {font-size: 12px;}
		 			.reg {font-size: 12px;} 
		 		}
		 	}
		 }
		 .form {
		 	.row {
		 		input[type='submit'] {height: 40px; font-size: 16px; border:0;} 
		 	}
		 }
		 .list-issue {   
		 	.box-issue {
		 		width: 30.6%; margin: 0 4% 5% 0; 
		 		@include boxSizing(border-box); 
		 		.issue {
		 			left: 13px; top: 13px; padding: 30px 20px;
		 			h3 {font-size: 20px; line-height: 22px;}
		 			h6 {font-size: 14px; font-weight: 500;}
		 		}  
		 	}
		 }
		 .wrap-btnloadmore {
		 	padding-bottom: 120px; margin-top: 25px; 
		 }
		  .box-wrap-news {
		  	.list-news {
		  		padding-bottom: 27px; margin-bottom: 27px;  
		  		.foto {width: 44.7%; margin-right: 5%;}
		  		.text-news {
		  			width: 50.2%;
					h3 {line-height: 28px; margin: 15px 0 20px 0;}
					p {line-height: 22px;}
					h6 {font-size: 14px;} 
		  		} 
		  	}
		  }
		  .wrap-list {
		  	.list-organi {
		  		.org {
		  			.org-left,
		  			.org-right {@include boxSizing(border-box);} 
		  			.org-left {padding-right: 10px;}
		  			.org-right {padding-left: 10px;} 
		  		}
		  	}
		  }

		.bot-img { 
			.lis-bot-img {
				a {
					h5 {font-size: 14px;}
				}
			}
		} 

		.wrap-box-testimoni {
			.list-testimoni {
				.box-testi {
					.text {
						p {font-size: 15px; line-height: 24px;}    
					}
				}
			}
		}

		.wrap-slider {padding-top: 30px;}     
		.bot-img {  
			.lis-bot-img {
				width: 33.333%; margin-bottom: 25px;   
				a {
					h5 {margin-top: 10px;}
				}
			}
		}

	}
	.sub-content {
		padding-left: 80px;
		.sign {margin: 85px 0;} 
	}
	
}


.box-calendar {
	.box-list-event {
		.list-event {
			.title-event {
				h5 {}
				span {font-size: 12px;}
			}
		}
	}
}

.calendar-event { 
	.ui-datepicker { 
		table {margin-bottom: 0;} 
		.ui-datepicker-title {font-size: 26px;}    
		.ui-datepicker-next {
			&:before {top:11px;}
			span {font-size:12px;} 
		}
		.ui-datepicker-prev {
			&:before {top:11px;}
			span {font-size:12px;}
		}
		table {
			th {font-size: 12.5px;}
			td {font-size: 10.5px;}
		}
	}
}
.calendar-event .ui-state-default, .calendar-event .ui-widget-content .ui-state-default, .calendar-event .ui-widget-header .ui-state-default, .calendar-event .ui-button, .calendar-event .ui-button.ui-state-disabled:hover, .calendar-event .ui-button.ui-state-disabled:active {
	font-size: 11px;
}
.calendar-event .ui-datepicker table td.wp_ev:before {width: 12px; height: 12px;}


footer {
	.top-footer {
		padding: 40px 0;
		 .top-info {
		 	.logo {
		 		width: 275px; margin-right: 45px; 
				img {width: 100%; height: auto;}  
		 	} 
		 	.part {
		 		padding-left: 30px; width: 210px;  
		 		h6 {font-size: 14px; margin-bottom: 15px;}
		 		p {font-size: 14px; line-height: 22px; margin-bottom: 5px;} 
		 		&:last-child {padding-top: 28px; height: auto; margin-right: 20px;}      
		 	}
		 }
	}
	.bot-footer {  
		padding: 18px 0; 
	 	.ic-partner {  
	 		a {
	 			display: inline-block; padding-right: 10px; 
	 			img {width: 100%;}
	 			&.part-1 {width: 28px;} 
	 			&.part-2 {width: 78px;} 
	 			&.part-3 {width: 32px;} 
	 			&.part-4 {width: 88px;}  
	 		}
	 	}
	 	.desc {	 		
	 		.nav-bot {padding-bottom: 5px;}   	 		
	 	}
	 }
}

@media all and (max-width: 1366px) {

	header {
		.bot {
			.left_header {		
				.logobot{ width: 200px; }				
			} 
		}
	}
}