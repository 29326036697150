@charset "UTF-8";

/// Truncates text and adds an ellipsis to represent overflow.
///
/// @param {Number} $width [100%]
///   Max-width for the string to respect before being truncated
///
/// @example scss - Usage
///   .element {
///     @include ellipsis;
///   }
///
/// @example css - CSS Output
///   .element {
///     display: inline-block;
///     max-width: 100%;
///     overflow: hidden;
///     text-overflow: ellipsis;
///     white-space: nowrap;
///     word-wrap: normal;
///   }

@mixin ellipsis($width: 100%) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
