@mixin text-decoration($value) {
// <text-decoration-line> || <text-decoration-style> || <text-decoration-color>
  @include prefixer(text-decoration, $value, moz);
}

@mixin text-decoration-line($line: none) {
// none || underline || overline || line-through
  @include prefixer(text-decoration-line, $line, moz);
}

@mixin text-decoration-style($style: solid) {
// solid || double || dotted || dashed || wavy
  @include prefixer(text-decoration-style, $style, moz webkit);
}

@mixin text-decoration-color($color: currentColor) {
// currentColor || <color>
  @include prefixer(text-decoration-color, $color, moz);
}
